import tinymce from 'tinymce/tinymce';
import 'tinymce/themes/silver';
import 'tinymce/plugins/table';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/code';
import 'tinymce/plugins/image';
import 'tinymce/icons/default';
//import 'tinymce/icons/advcode';

require.context(
  '!file-loader?name=[path][name].[ext]&context=node_modules/tinymce&outputPath=js!tinymce/skins',
  true,
  /.*/
);

function tinyMCE() {
  tinymce.remove() // https://github.com/spohlenz/tinymce-rails/issues/145#issuecomment-49307568
  tinymce.init({
    selector: 'textarea.tinymce',
    // skin: 'oxide-dark',
    // paste_data_images: true,
    plugins: [ 'code', 'table', 'lists', 'paste', 'link', 'image' ],
    toolbar: 'undo redo | formatselect | ' +
    ' bold italic backcolor | alignleft aligncenter ' +
    ' alignright alignjustify lineheight | bullist numlist outdent indent | link image | code |' +
    ' removeformat | help',
    fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt 60pt',
    lineheight_formats: '1 1.1 1.2 1.3 1.4 1.5 2',
    placeholder: 'Písať sem...',
    /* enable title field in the Image dialog*/
    image_title: true,
    /* enable automatic uploads of images represented by blob or data URIs*/
    automatic_uploads: true,
    images_reuse_filename: true,
    /*
      URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
    */
    //  images_upload_url: 'pages/upload_image',
  
    images_upload_handler: function (blobInfo, success, failure) {
      var xhr, formData;
      xhr = new XMLHttpRequest();
      xhr.withCredentials = false;
      xhr.open('POST', '/pages');
      var token = AUTH_TOKEN;     //document.getElementById("_token").value;
      xhr.setRequestHeader("X-CSRF-Token", token);
      xhr.onload = function() {
          var json;
          if (xhr.status != 200) {
              failure('HTTP Error: ' + xhr.status);
              return;
          }
          json = JSON.parse(xhr.responseText);

          if (!json || typeof json.location != 'string') {
              failure('Invalid JSON: ' + xhr.responseText);
              return;
          }
          success(json.location);
      };
      formData = new FormData();
      formData.append('file', blobInfo.blob(), blobInfo.filename());
      xhr.send(formData);
    },
    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
  });
}

// if you're using a language file, you can place its content here

export { tinyMCE };
